// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain: #2196f3;
$primaryDark: #1e88e5;
$primary200: #90caf9;
$primary800: #1565c0;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

//-----------------------|| DARK THEME VARIANTS ||-----------------------//

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

//custom
$darkBtnBig: #7c4dff;
$darkBtnBigHover: #a57bff;

//-----------------------|| JAVASCRIPT ||-----------------------//

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    //-----------------------|| DARK THEME VARIANTS ||-----------------------//

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;

    //custom
    darkBtnBig: $darkBtnBig;
    darkBtnBigHover: $darkBtnBigHover;
}

:global {
    main {
        @media (min-width: 960px){
            margin-top: 86px !important;
        }

        @media (max-width: 959px){
            margin-top: 40px !important;
        }

        >div {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    //іконка в меню - мои закази
    .menu-top-orders {
        fill: $darkSecondary800;
    }

    .home-header-top {
        padding-top: 25px !important;

        h1, h3 {
            color: #fff !important;
        }
    }

    .home-header-form {
        h1, h3 {
            color: #fff !important;
        }

        .gift-alert {
            text-align: left;
            background-color: #1a223fa6;
            padding: 10px 0px;
            margin-top: 15px;
            border-radius: 10px;
        }

        .form-block {
            background-color: #1a223fa6;
            padding: 25px 15px;
            border-radius: 0px 0px 10px 10px;

            .add-order {
                width: 100%;
            }

            .total {
                color: #ffffff;
            }

            button.btn-big-custom {
                padding-left: 0px;
                padding-right: 0px;

                >span {
                    font-size: 18px;
                }
            }
        }

        .social-btn {
            color: #fff;
            border-radius: 10px;
            display: inline-block;
            padding: 5px;
            text-align: center;
            margin-right: 5px;
            align-items: stretch;
            background-color: #22244f;
            border: 1px solid #22244f;
        }
        .social-btn-active, .social-btn:hover {
            color: #fff;
            border: 1px solid #7c4dff;
        }
    }

    .btn-big-custom {
        background-color: $darkBtnBig;
        color: #fff;

        &:hover {
            background-color: $darkBtnBigHover;
            color: #fff;
        }
    }

    .package-sale-round {
        text-align: center;

        @keyframes animationFontSize {
            0% {font-size: 20px;}
            50% {font-size: 30px;}
            100% {font-size: 20px;}
        }

        @keyframes animationShadowSvg {
            0% {height: 2.5em;}
            50% {font-size: 20px; height: 1.5em;}
            100% {height: 2.5em;}
        }

        div {
            z-index: 1;
            color: #fff;
            background-color: $darkBtnBig;
            font-size: 25px;
            padding: 20px;
            display: inline-flex;
            border: 1px solid #fff;
            border-radius: 50%;
            font-weight: bold;
            position: relative;
            width: 80px;
            height: 80px;
            align-items: center;
            justify-content: center;
            bottom: -40px;

            & :local {
                animation: animationFontSize normal 3s infinite ease-in-out;
            }

            svg {
                & :local {
                    animation: animationShadowSvg normal 3s infinite ease-in-out;
                }
            }
        }
    }

    .package-block {
        padding: 0px 15px;

        >div {
            margin-top: -50px;
        }

        .package-view {
            padding-top: 50px;
            background-color: #29314f;

            & :local {
                animation: animationPackage normal 5s infinite ease-in-out;
            }

            @keyframes animationPackage {
                0% {
                    transform: rotate(0deg);
                }
                3% {
                    transform: rotate(-0.3deg);
                }
                6% {
                    transform: rotate(0.5deg);
                }
                9% {
                    transform: rotate(-0.3deg);
                }
                12% {
                    transform: rotate(0.5deg);
                }
                15% {
                    transform: rotate(-0.3deg);
                }
                18% {
                    transform: rotate(0.5deg);
                }
                22% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(0deg);
                }
            }

            .timerBlock {
                color: #ff0000;
                background: #ffffff;
                border-radius: 12px;
                padding: 15px 0px;
                text-align: center;
                font-weight: 700;
                font-size: 24px;
            }

            .price-amount {
                font-size: 35px;
                font-weight: 700;
                color: #ffffff;

                & > span {
                    font-size: 20px;
                    font-weight: 500;
                    color: #bdc8f0;
                }
            }

            .package-title {
                color: #fff;
                font-size: 25px;
                font-weight: 500;
                position: relative;
                text-transform: uppercase;
            }

            .package-title-sale {
                font-size: 25px;
                position: relative;
                color: #55fffd;
            }

            .package-price-list {
                margin: 0;
                padding: 0;
                font-weight: bold;

                & > li {
                    padding: 5px 0;

                    & svg {
                        // fill: 'white'
                    }
                }
            }

            .btn-big-custom {
                width: 100%;
            }
        }
    }

    .we-the-best {
        background-color: #2b365e;
        margin: 50px 0px 25px;
        padding: 50px 0px;

        h1, h2, h3 {
            color: #fff !important;
        }

        .we-the-best-view {
            background-color: #111935;
            padding: 14px;
            margin-top: 50px;
            border-radius: 8px;

            div {
                position: absolute;
                font-size: 36px;
                margin-top: -40px;
                background-color: #364597;
                padding: 7px;
                border-radius: 8px;

                svg {
                    stroke: #ffffff;
                }
            }
        }
    }

    .how-match-card {
        background: #29314f;
        margin-top: 25px;
        padding: 16px;

        .card-title {
            color: #ffffff;
        }

        .card-price {
            font-size: 24px;
            color: #55fffd;

            span {
                font-size: 14px;
            }
        }

        .card-item {
            color: #b8b8b8;
        }

        .card-description {
            color: #ffffff;
        }

        button {
            width: 100%;
            background-color: #29314f;
            border: 1px solid $darkBtnBig;

            &:hover {
                background-color: $darkBtnBig;
                border: 1px solid $darkBtnBigHover;
            }
        }
    }

    .home-reviews {
        background-color: #2b365e;
        margin: 50px 0px 25px;
        padding: 50px 25px;

        h1, h2, h3 {
            color: #fff !important;
        }

        h6 {
            margin-top: -10px;
        }

        .home-reviews-block {
            .review-card {
                margin-top: 25px;
                padding: 16px;
                background: #1a223f;

                .MuiTypography-h3 {
                    color: #ffffff;
                }

                .rating-stars {
                    color: #2196f3;
                }
            }
        }
    }

    .home-block-faq {
        background-color: #2b365e;
        width: 100%;

        h1, h2, h3 {
            color: #fff !important;
        }

        .MuiAccordion-rounded {
            background-color: #2b365e;

            .MuiAccordionSummary-gutters {
                color: #ffffff;
            }
        }
    }

    .footer {
        padding: 0 25px;
        background-color: #111935;
        border-top: 1px solid #3c435c;
        margin-top: -16px;
        margin-left: -26px;
        width: calc(100% + 50px);
        padding-bottom: 15px;
    }
    .footer-link {
        color: #bdc8f0;
        text-decoration: underline;
    }
    .footer-payments {
        justify-content: end;
    }

    .modal-block > div {
        background-color: #29314f;

        .MuiTypography-h5, h2 {
            color: #fff;
        }

        .Mui-error {
            color: red;
        }

        h5 {
            color: #fff;
        }

        .package-price-list {
            margin-top: -25px;

            li {
                padding-left: 0px;
            }
        }
    }

    .MuiModal-root {
        .MuiTypography-h5 {
            color: #fff;
        }

        .home-header-form > div {
            background-color: #29314f;
        }
    }

    @media (max-width: 959px){
        .modal-block > div{
            width: 90%;

            button.btn-big-custom {
                padding-left: 0px;
                padding-right: 0px;

                >span {
                    font-size: 18px;
                }
            }
        }
    }
}
